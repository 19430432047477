import React, {useEffect, useState} from 'react';
import {graphql} from "gatsby";
import Markdown from 'markdown-to-jsx';

import Layout from "../../components/layouts/Layout";
import SectionLayoutGrid from "../../components/layouts/SectionLayoutGrid";

import Col from "../../components/styled/grid/Col";
import {Body, Title} from "../../components/styled/typography/Typography";
import {PressTemplateImg, PressTemplateTable} from "../../components/styled/pages/about/press/PressComponents";

const PressTemplate = ({data}) => {

    const {content, SEO, title, table, table_avatar} = data.presspost;
    const [actualLang, setActualLang] = useState("hu");

    useEffect(() => {
        if (window.location.host.includes(".hu") && document.documentElement.lang === "hu") {
            setActualLang("hu")
        } else if(window.location.host.includes(".hu") && document.documentElement.lang === "en"){
            setActualLang("en")
        } else if(window.location.host.includes(".eu") && document.documentElement.lang === "hu"){
            setActualLang("hu")
        } else if(window.location.host.includes(".eu") && document.documentElement.lang === "en"){
            setActualLang("en")
        } else if(window.location.host.includes(".hu") && document.documentElement.lang === ""){
            setActualLang("hu")
        } else if(window.location.host.includes(".eu") && document.documentElement.lang === ""){
            setActualLang("en")
        }
    }, []);

    return (
        <Layout lang={actualLang} seo={SEO}>
            <SectionLayoutGrid customBackground="middle" wide={(table && table.length!==0) ? "true" : ""}>
                <Col>
                    <Title color="white">
                        {title}
                    </Title>
                </Col>
                <Col className="markdown" span={table.length!==0 ? 7 : ""}>
                    <Markdown children={content}/>
                </Col>
                {table && table.length!==0 &&
                <Col span={5}>
                    {table_avatar &&
                    <PressTemplateImg src={table_avatar.url} alt="logo"/>}
                    {table.map((item) => (
                        <PressTemplateTable>
                            <Body bold color="white">{item.title}:</Body>
                            <Body small color="white" textAlign="right">{item.cell}</Body>
                        </PressTemplateTable>
                    ))}
                </Col>
                }
            </SectionLayoutGrid>
        </Layout>
    );
};

export const query = graphql`
   query GetSinglePressPosts($slug: String) {
         presspost: strapiPressPosts(slug: { eq: $slug }) {
            title
            slug
            content
            table_avatar {
              url
            }
            table {
              cell
              id
              title
            }
            SEO {
              title
              isIndexable
              description
              keywords
              preview {
                url
              }
            }
          }
   }
 `

export default PressTemplate;
import styled from "styled-components";

export const PressCardWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`

export const PressTemplateTable = styled.div`
  display: grid;
  grid-auto-flow: column;
  height: 5rem;
  align-content: center;
  border-bottom: 1px solid var(--body-text);
  box-sizing: border-box;
`

export const PressTemplateImg = styled.img`
  width: 100%;
  border: 1px solid var(--body-text);
  box-sizing: border-box;
`